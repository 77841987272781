import { render, staticRenderFns } from "./HealthyLifeDetails.vue?vue&type=template&id=17b4df37&scoped=true&"
import script from "./HealthyLifeDetails.vue?vue&type=script&lang=js&"
export * from "./HealthyLifeDetails.vue?vue&type=script&lang=js&"
import style0 from "./HealthyLifeDetails.vue?vue&type=style&index=0&id=17b4df37&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17b4df37",
  null
  
)

export default component.exports